.listContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  padding: 4px;
  margin: 0;
  font-size: calc(10px + 1.5vmin);
  list-style-type: none;
}

.itemSelectorBox-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  max-height: 100dvh;
  padding-top: 24px;
  overflow: auto;
  border-right: 1px solid gray;
}

.itemSelectorBox-container__collapsed {
  transition: width 0.2s ease-out;
}

.itemSelectorBox-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.itemSelectorBox-container__collapsed .itemSelectorBox-header-container {
  flex-direction: column-reverse;
  align-items: center;
}

.itemSelectorBox-header-text {
  font-weight: 500;
}

.itemSelectorBox-container__collapsed .itemSelectorBox-header-text {
  writing-mode: vertical-rl;
}

.itemSelectorBox-container__collapsed .listContainer {
  display: none;
}

.itemSelectorBox-container__hided {
  display: none;
}

.itemSelectorBox-loader {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  animation: rotate 1s linear infinite;
}

.itemSelectorBox-loader::before {
  position: absolute;
  inset: 0;
  box-sizing: border-box;
  content: "";
  border: 5px solid #fff;
  border-radius: 50%;
  animation: prix-clip-fix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prix-clip-fix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

@media screen and (width >= 769px) {
  .itemSelectorBox-container {
    width: 20dvw;
    min-width: 200px;
  }

  .itemSelectorBox-container__collapsed {
    width: 85px;
    min-width: 0;
  }
}

@media screen and (width <= 768px) {
  .itemSelectorBox-container {
    flex: 1;
  }

  .itemSelectorBox-container .iconButton {
    display: none !important;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 64px;
  min-width: 64px;
  height: 100dvh;
  padding: 16px 0;
  background-color: #3a3f4b;
}

.sidebar-bottomItems,
.sidebar-topItems {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media screen and (width <= 768px) {
  .sidebar {
    width: 48px;
    min-width: 48px;
  }
}

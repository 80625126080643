.note-author {
  display: flex;
  gap: 7px;
  align-items: center;
  justify-content: flex-end;
  height: 4em;
  margin-top: auto;
}

.note-author img {
  max-height: 60px;
  border-radius: 50%;
}

.item {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100dvh;
  padding: 10px;
  padding-top: 24px;
  overflow: auto;
}

.item-headerBox {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: end;
  justify-content: space-between;
}

.item-headerTitle {
  display: flex;
  flex: 2;
  overflow: hidden;
  font-weight: 500;
  text-align: center;
  word-break: break-word;
}

.item-headerActions {
  display: flex;
  flex: 1 72px;
  gap: 6px;
  justify-content: end;
  margin-bottom: auto;
  overflow: hidden;
}

.item-headerActions .iconButton {
  min-width: 36px;
  max-width: 64px;
  margin-top: auto;
}

.item-author {
  display: flex;
  gap: 7px;
  align-items: center;
  justify-content: flex-end;
  height: 4em;
  margin-top: auto;
}

.item-author img {
  max-height: 60px;
  border-radius: 50%;
}

.item-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  margin: 0;
  font-size: calc(10px + 1.5vmin);
}

.item-multiLineText {
  display: flex;
  flex: auto;
  resize: none;
}

.item-multiLineText__readOnly {
  overflow: auto;
  font: inherit;
  color: inherit;
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
}

.item-editModeButtons {
  display: flex;
  gap: 1em;
  align-items: end;
  justify-content: end;
}

@media screen and (width <= 768px) {
  .item {
    width: 100%;
  }

  .item-author img {
    height: 48px;
  }

  .item-headerActions .iconButton {
    width: 36px;
  }
}

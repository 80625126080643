.iconButton {
  display: flex;
  gap: 0.2em;
  padding: 8px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 10px;
  outline: none;
  transition: background-color 0.1s;
}

.iconButton-text {
  margin: auto;
}

.iconButton-icon {
  width: 100%;
  height: 100%;
}

.iconButton:hover {
  background-color: #585b63;
}

.iconButton:active {
  background-color: #848996;
  opacity: 0.8;
}

.iconButton-icon__center {
  justify-content: center;
}

.iconButton-icon__left {
  justify-content: space-between;
}

.iconButton-icon__right {
  flex-direction: row-reverse;
  justify-content: space-between;
}

@media screen and (width <= 768px) {
  .iconButton {
    padding: 4px;
  }
}

.listItem__active {
  filter: brightness(140%) !important;
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3em;
  overflow: hidden;
  line-height: 1.5;
  color: #d3deff;
  cursor: pointer;
  background-color: #323641;
  border-radius: 8px;
  transition: 0.1s;
}

.listItem:hover {
  filter: brightness(115%);
}

.listItem-link {
  display: -webkit-box;
  flex-grow: 1;
  height: 3em;
  padding: 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  white-space: normal;
  -webkit-box-orient: vertical;
}

.buttonsBar.iconButton {
  max-height: 40px; /* Ограничиваем высоту кнопок до высоты бара */
  margin-right: 10px; /* Отступ между кнопками */
}

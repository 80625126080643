.errorPage-container {
  display: flex;
  gap: 100px;
  align-items: center;
  justify-content: center;
  width: 100dvw;
  height: 100dvh;
  color: white;
  background-color: #282c34;
}

.error-picture {
  width: 30dvw;
  height: auto;
  border-radius: 16px;
}

.errorPage-link {
  color: hsl(230deg 30% 85% / 100%);
  text-decoration: underline;
  cursor: pointer;
}

.errorPage-link:hover {
  opacity: 0.8;
}

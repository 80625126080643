.home-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 2em;
  overflow: auto;
  text-align: center;
  transition: opacity 0.3s;
}

.home-container a {
  margin-right: 1em;
  color: #38d;
  text-decoration: none;
}

.home-container a:last-child {
  margin-left: 1em;
}

.home-container a:hover {
  cursor: pointer;
  opacity: 0.5;
}
